import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { uiReducer } from "../reducers/uiRedurcer";
import { calendarReducer } from "../reducers/calendarReducer";




export const store = configureStore({
        reducer:{
            ui: uiReducer,
            calendar: calendarReducer,
        }
    },
    applyMiddleware(thunk)
);